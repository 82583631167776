import {
  Layout,
  Menu,
  Grid,
  Button,
  Typography,
  Drawer,
  Input,
  Row,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import "MainLayout/mainlayout.less";
import { IdenciaImages } from "../../Assets/Images";
import { Link, useLocation } from "react-router-dom";
import SubMenu from "antd/lib/menu/SubMenu";
import { SettingOutlined, ApartmentOutlined } from "@ant-design/icons";
import {
  getCurrentOrganization,
  getCurrentOrganizationIntegrations,
} from "store/slices/loginSlice";
import { useSelector } from "react-redux";
const { Sider } = Layout;

export default function Sidebar(props) {
  const inputRef = useRef(null);

  const { open, onClose, setOpen } = props;
  const { IdenciaLogo, LogoResponsive } = IdenciaImages.Auth;
  const {
    Items,
    HelpIcon,
    users,
    Dashboard,
    report,
    MessageIcon,
    Documents,
    rfid,
  } = IdenciaImages.sidebarIcons;
  const { ProjectSidebarIcon } = IdenciaImages.Projects;
  const [collapsed, setCollapsed] = useState(true);
  const [selectedKey, setSelectedKey] = useState("");
  const location = useLocation();
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const currentOrganization = useSelector(getCurrentOrganization);
  const currentOrganizationIntegrations = useSelector(
    getCurrentOrganizationIntegrations
  );

  const initialAdminHeaderMenu = [
    {
      name: "Dashboard",
      path: "/Dashboard",
      key: "Dashboard",
      icon: <img className="sidebar-icon" src={Dashboard} alt="" />,
    },

    {
      name: "Items",
      path: "/Items",
      key: "Items",
      icon: <img className="sidebar-icon" src={Items} alt="" />,
    },
    {
      name: "Projects",
      path: "/projects",
      key: "projects",
      icon: <img className="sidebar-icon" src={ProjectSidebarIcon} alt="" />,
    },
    {
      name: "Report",
      path: "/reports",
      key: "reports",
      icon: <img className="sidebar-icon" src={report} alt="" />,
    },
    {
      name: "Bold Report",
      path: "/bold-reports",
      key: "bold-reports",
      icon: <img className="sidebar-icon" src={report} alt="" />,
    },
    {
      name: "Shipments",
      path: "/shipments",
      key: "shipments",
      icon: <img className="sidebar-icon" src={ProjectSidebarIcon} alt="" />,
    },
    {
      name: "Documents",
      path: "/documents",
      key: "documents",
      icon: <img className="sidebar-icon" src={Documents} alt="" />,
    },
    // {
    //   name: "RFID",
    //   path: "/rfid",
    //   key: "rfid",
    //   icon: <img className="sidebar-icon" src={rfid} alt="" />,
    // },
    // {
    //   name: "Items Groups",
    //   path: "/Items-Groups",
    //   key: "Items-Groups",
    //   icon: <img className="sidebar-icon" src={ItemGroup} alt="" />,
    // },
    {
      name: "Setup",
      key: "Setup",
      icon: <SettingOutlined className="sidebar-icon" />,
      subMenu: [
        {
          name: "Item Type",
          path: "/item-type",
          key: "item-type",
          // icon: <img src={Items} alt="cvc" className="sidebar-icon" />,
        },
        {
          name: "Custom Properties",
          path: "/custom-properties",
          key: "custom-properties",
          // icon: <img src={Items} alt="cvc" className="sidebar-icon" />,
        },
        {
          name: "Processes",
          path: "/processes",
          key: "processes",
          // icon: <img src={Items} alt="cvc" className="sidebar-icon" />,
        },
        {
          name: "Users",
          path: "/users",
          key: "users",
          // icon: <img src={users} className="sidebar-icon" alt="cvc" />,
        },
        {
          name: "Customers",
          path: "/customers",
          key: "customers",
          // icon: <img src={users} className="sidebar-icon" alt="cvc" />,
        },
      ],
    },
  ];

  const [headerMenu, setHeaderMenu] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (currentOrganizationIntegrations?.length > 0) {
      const integrationsSubMenu = [];
      currentOrganizationIntegrations.forEach((subscription) => {
        if (subscription.isSubscribed) {
          if (subscription?.Modules?.key === "ForneyVault") {
            integrationsSubMenu.push({
              name: "Forney Vault",
              path: "/forney-vault",
              key: "ForneyVault",
            });
          } else if (subscription?.Modules?.key === "NcdotHicams") {
            integrationsSubMenu.push({
              name: "NCDOT HICAMS",
              path: "/ncdot-hicams",
              key: "NcdotHicams",
            });
          }
        }
      });
      if (integrationsSubMenu?.length > 0) {
        initialAdminHeaderMenu.push({
          name: "Integration",
          key: "Integration",
          icon: <ApartmentOutlined className="sidebar-icon" />,
          subMenu: integrationsSubMenu,
        });
      }
    }
    setHeaderMenu([...initialAdminHeaderMenu]);
  }, [currentOrganizationIntegrations]);

  useEffect(() => {
    filterMenu();
  }, [searchQuery]);

  const filterMenu = () => {
    const query = searchQuery.toLowerCase();
    if (query) {
      const filtered = initialAdminHeaderMenu.filter((item) => {
        return (
          item.name.toLowerCase().includes(query) ||
          (item.subMenu &&
            item.subMenu.some((subItem) =>
              subItem.name.toLowerCase().includes(query)
            ))
        );
      });
      setHeaderMenu(filtered);
    } else {
      setHeaderMenu([...initialAdminHeaderMenu]);
    }
  };

  useEffect(() => {
    switch (window.location.pathname) {
      case "/":
        setSelectedKey("Items");
        break;
      case "/bold-reports/view":
        setSelectedKey("bold-reports");
        break;
      default:
        setSelectedKey(() => window.location.pathname.replace("/", ""));
        break;
    }
  }, [location]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      // Check if Ctrl (Cmd) + K is pressed
      if ((event.ctrlKey || event.metaKey) && event.key === "k") {
        // Prevent default browser behavior
        event.preventDefault();
        // Focus on the input when Ctrl (Cmd) + K is pressed
        inputRef.current.focus();
      }
    };

    // Add event listener for keydown
    document.addEventListener("keydown", handleKeyPress);

    return () => {
      // Remove event listener when component unmounts
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const handleInputClick = () => {
    // Focus on the input when it's clicked
    inputRef.current.focus();
  };

  return (
    <>
      <Sider
        collapsed={collapsed}
        className="sidebar desktop-sidebar"
        breakpoint="lg"
        // width={screens.xxl ? "300px" : "250px"}
        width="250px"
        collapsedWidth={65}
        onBreakpoint={(broken) => {}}
        onCollapse={(collapsed, type) => {
          setCollapsed(collapsed);
        }}
      >
        <div className="logo-wrapper">
          {!collapsed && (
            <img
              src={collapsed ? LogoResponsive : IdenciaLogo}
              alt=""
              className="logo"
            />
          )}

          <Button
            type="link"
            onClick={() => {
              setSearchQuery("");
              setCollapsed(!collapsed);
            }}
          >
            <svg
              width="27"
              height="27"
              viewBox="0 0 27 27"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="LeftCircle" clipPath="url(#clip0_181662_9675)">
                <g id="Vector">
                  <path
                    d="M16.5299 8.34675L9.39148 13.5119C9.36182 13.5333 9.33767 13.5614 9.321 13.594C9.30434 13.6265 9.29566 13.6625 9.29566 13.6991C9.29566 13.7357 9.30434 13.7717 9.321 13.8042C9.33767 13.8368 9.36182 13.8649 9.39148 13.8863L16.5299 19.0514C16.6837 19.1617 16.8984 19.0514 16.8984 18.8628V17.5019C16.8984 17.2059 16.7562 16.9244 16.5154 16.7503L12.2962 13.7005L16.5154 10.6479C16.7562 10.4738 16.8984 10.1952 16.8984 9.8963V8.53537C16.8984 8.34675 16.6837 8.23648 16.5299 8.34675Z"
                    fill="#F8F8F8"
                    fillOpacity="0.85"
                  />
                  <path
                    d="M13.8818 0.699951C6.70282 0.699951 0.881836 6.52093 0.881836 13.7C0.881836 20.879 6.70282 26.7 13.8818 26.7C21.0609 26.7 26.8818 20.879 26.8818 13.7C26.8818 6.52093 21.0609 0.699951 13.8818 0.699951ZM13.8818 25.2168C7.92157 25.2168 2.36497 19.6602 2.36497 13.7C2.36497 7.73968 7.92157 2.18309 13.8818 2.18309C19.8421 2.18309 25.3987 7.73968 25.3987 13.7C25.3987 19.6602 19.8421 25.2168 13.8818 25.2168Z"
                    fill="#F8F8F8"
                    fillOpacity="0.85"
                  />
                </g>
              </g>
              <defs>
                <clipPath id="clip0_181662_9675">
                  <rect
                    width="26"
                    height="26"
                    fill="white"
                    transform="translate(0.881836 0.699951)"
                  />
                </clipPath>
              </defs>
            </svg>
          </Button>
        </div>

        <div className="new"> {currentOrganization?.name}</div>
        {collapsed ? (
          <></>
        ) : (
          <Row
            style={{ justifyContent: "center", padding: "10px 12px 10px 0" }}
          >
            <Input
              onClick={handleInputClick}
              ref={inputRef}
              placeholder="Search"
              className="side-searchbar"
              onChange={(e) => setSearchQuery(e.target.value)}
              prefix={
                <div style={{ marginTop: "8px", marginRight: "5px" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                  >
                    <path
                      d="M12.88 11.0965L10.4647 8.68124C11.1844 7.75089 11.5737 6.61339 11.5737 5.41696C11.5737 3.98481 11.0147 2.64196 10.004 1.62946C8.9933 0.616957 7.64687 0.0598145 6.21652 0.0598145C4.78616 0.0598145 3.43973 0.618743 2.42902 1.62946C1.41652 2.64017 0.859375 3.98481 0.859375 5.41696C0.859375 6.84731 1.4183 8.19374 2.42902 9.20446C3.43973 10.217 4.78438 10.7741 6.21652 10.7741C7.41295 10.7741 8.54866 10.3848 9.47902 9.66696L11.8943 12.0804C11.9079 12.0941 11.924 12.1048 11.9418 12.1122C11.9596 12.1196 11.9786 12.1234 11.9979 12.1234C12.0171 12.1234 12.0362 12.1196 12.0539 12.1122C12.0717 12.1048 12.0878 12.0941 12.1014 12.0804L12.88 11.3037C12.8936 11.2901 12.9044 11.2739 12.9118 11.2561C12.9191 11.2384 12.9229 11.2193 12.9229 11.2001C12.9229 11.1809 12.9191 11.1618 12.9118 11.144C12.9044 11.1263 12.8936 11.1101 12.88 11.0965ZM9.04509 8.24553C8.28795 9.00089 7.28438 9.41696 6.21652 9.41696C5.14866 9.41696 4.14509 9.00089 3.38795 8.24553C2.63259 7.48839 2.21652 6.48481 2.21652 5.41696C2.21652 4.3491 2.63259 3.34374 3.38795 2.58839C4.14509 1.83303 5.14866 1.41696 6.21652 1.41696C7.28438 1.41696 8.28973 1.83124 9.04509 2.58839C9.80045 3.34553 10.2165 4.3491 10.2165 5.41696C10.2165 6.48481 9.80045 7.49017 9.04509 8.24553Z"
                      fill="#F8F8F8"
                      fillOpacity="0.85"
                    />
                  </svg>
                </div>
              }
              suffix={<div className="searchbar-k-icon">⌘K</div>}
            />
          </Row>
        )}
        <Menu
          className="sidebar-menu-wrapper"
          theme="dark"
          mode="inline"
          selectedKeys={selectedKey}
        >
          {headerMenu?.map((item) =>
            item?.subMenu?.length ? (
              <SubMenu
                key={item.key}
                icon={item.icon}
                id={item.key}
                title={item?.name}
              >
                {item.subMenu.map((subItem, index) => (
                  <Menu.Item key={subItem.key} icon={subItem?.icon}>
                    <Link
                      to={subItem.path}
                      // onClick={() => {
                      //   setCollapsed(true);
                      // }}
                    >
                      {subItem.name}
                    </Link>
                  </Menu.Item>
                ))}
              </SubMenu>
            ) : (
              <Menu.Item key={item.key} icon={item.icon} id={item.key}>
                <Link to={item.path}>{item.name}</Link>
              </Menu.Item>
            )
          )}
        </Menu>
        {/* <Button
          className="help-btn primary-btn "
          type="primary"
          size="large"
          onClick={() => window.open("https://IdenciaLogo.com/help")}
        >
          <img src={HelpIcon} alt="" className="sidebar-icon" /> Help
        </Button> */}
      </Sider>

      <Drawer
        title={<img src={IdenciaLogo} alt="" className="logo" />}
        placement="left"
        onClose={onClose}
        open={open}
        className={"layout sidebar-drawer"}
        closeIcon={<></>}
      >
        <div className="sidebar">
          {screens.xs ? (
            <Typography.Title level={5} className="menu-title">
              Menu
            </Typography.Title>
          ) : (
            <></>
          )}
          <Menu theme="dark" mode="inline" selectedKeys={selectedKey}>
            {headerMenu?.map((item) =>
              item?.subMenu?.length ? (
                <SubMenu
                  key={item.key}
                  icon={item.icon}
                  id={item.key}
                  title={item?.name}
                >
                  {item.subMenu.map((subItem, index) => (
                    <Menu.Item key={subItem.key} icon={subItem?.icon}>
                      <Link
                        to={subItem.path}
                        onClick={() => {
                          setCollapsed(true);
                          setOpen(false);
                        }}
                      >
                        {subItem.name}
                      </Link>
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item key={item.key} icon={item.icon} id={item.key}>
                  <Link
                    to={item.path}
                    onClick={() => {
                      setCollapsed(true);
                      setOpen(false);
                    }}
                  >
                    {item.name}
                  </Link>
                </Menu.Item>
              )
            )}
          </Menu>
          <Button
            className="help-btn primary-btn "
            type="primary"
            size="large"
            onClick={() => window.open("https://  .com/help")}
          >
            <img src={HelpIcon} alt="" className="sidebar-icon" /> Help
          </Button>
        </div>
      </Drawer>
    </>
  );
}
